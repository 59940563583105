import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

export const arrowStylesCommon = {
  // Rob - We need a consistent was to position the nav narrows
  // they should be in relation to the image container but that
  // means redoing the pagination rendering to the main container
  // isn't position: sticky.
  marginTop: `-${spacing(2)}`,
  color: palette.grey.main,
  width: '2rem',
  height: '2rem'
};

const styles: Record<string, SxProps> = {
  mainContainer: {
    backgroundColor: palette.grey['100'],
    '& .ssr_markup': {
      '& .alice-carousel__stage': {
        overflow: 'hidden',
        display: '-webkit-box',
        paddingLeft: 0
      },
      '& .alice-carousel__stage-item': {
        listStyle: 'none',
        width: {
          xs: '17rem !important',
          md: '30% !important'
        }
      }
    }
  },

  title: {
    width: '100%',
    mb: spacing(2),
    color: palette.primary.main
  },

  root: {
    maxWidth: '140rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: {
      xs: '0.9375rem 0.9375rem 5rem 0.9375rem',
      md: '5rem'
    },
    backgroundColor: palette.grey['100'],
    position: 'relative',
    paddingBottom: '5rem',
    '.alice-carousel': {
      position: 'static',
      '.job-title': {
        color: palette.grey.main
      },
      '.__active': {
        '.name': {
          color: palette.primary.main
        },
        '.job-title': {
          color: palette.grey.main
        }
      }
    },
    '.alice-carousel__dots': {
      position: 'absolute',
      bottom: '0',
      left: '-0.75rem',
      display: {
        xs: 'flex'
      },
      justifyContent: {
        xs: 'center'
      },
      width: {
        xs: '100%'
      },
      mb: '1rem'
    },
    '.alice-carousel__prev-btn, .alice-carousel__next-btn': {
      width: 'auto'
    }
  },
  slideRoot: {
    display: 'flex',
    marginLeft: {
      xs: 'auto',
      md: 'auto'
    },
    paddingRight: '0.09rem',
    flexWrap: 'wrap',
    justifyContent: {
      sm: 'center',
      xs: 'flex-start'
    }
  },

  imgNameWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    width: {
      xs: '100%',
      sm: '95%',
      md: '100%'
    }
  },
  nameWrapper: {
    textAlign: 'left',
    position: 'absolute',
    bottom: '0',
    left: {
      lg: '13rem',
      sm: '13rem',
      md: '13rem',
      xs: '5.625rem'
    }
  },
  leftControl: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    left: spacing(2),
    top: 0,
    bottom: 0,
    background:
      'linear-gradient(to right, rgba(246, 246, 246, 0.88) 0%, rgba(246, 246, 246, 0) 100%)',
    cursor: 'pointer',
    visibility: {
      xs: 'hidden',
      sm: 'visible'
    }
  },
  arrowStylesLeft: {
    ...arrowStylesCommon,
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  arrowStylesRight: {
    ...arrowStylesCommon,
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  rightControl: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    right: spacing(2),
    top: 0,
    bottom: 0,
    background:
      'linear-gradient(to left, rgba(246, 246, 246, 0.88) 29.11%, rgba(246, 246, 246, 0) 100%)',
    cursor: 'pointer',
    visibility: {
      xs: 'hidden',
      sm: 'visible'
    }
  },
  imageOverlay: {
    position: 'absolute',
    right: 0,
    top: '7.5rem',
    opacity: 0.5,
    background:
      'linear-gradient(to left, rgba(246, 246, 246, 0.88) 50%, rgba(246, 246, 246, 0) 100%)',
    width: '25%',
    height: '6rem'
  },
  divider: {
    width: '100%',
    marginTop: spacing(2),
    marginBottom: spacing(2),
    position: 'relative',
    display: {
      sm: 'flex',
      xs: 'flex'
    },
    justifyContent: 'center',
    '.HRule': {
      width: '100%',
      height: '0.0625rem',
      borderTop: `${palette.grey['300']}`,
      borderColor: palette.primary.main,
      opacity: '0.8'
    },
    ':after': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 0.9375rem 0.9375rem',
      borderColor: `${palette.grey['100']} transparent`,
      display: 'block',
      width: '0',
      zIndex: '1',
      top: '-0.375rem',
      left: '46%'
    },
    ':before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 0.9375rem 0.9375rem',
      borderColor: `${palette.primary.main} transparent`,
      display: 'block',
      width: '0',
      zIndex: '1',
      top: '-0.4375rem',
      left: '46%'
    }
  },
  testimonyWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: {
      xs: 'flex-start',
      sm: 'center'
    }
  },
  testimony: {
    display: 'inline-block',
    fontStyle: 'italic',
    left: {
      xs: '1rem'
    },
    position: 'relative',
    marginTop: {
      sm: '2rem',
      xs: '1'
    },
    textAlign: {
      sm: 'center',
      xs: 'center'
    },
    maxWidth: {
      sm: '70%',
      xs: '90%'
    }
  },
  carouselDot: {
    background: palette.primary['600'],
    opacity: '0.2',
    WebkitFontSmoothing: 'antialiased',
    WebkitTextSizeAdjust: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.5',
    boxSizing: 'border-box',
    transition: 'opacity .25s ease-in',
    boxShadow: `0.0625rem 0.0625rem 0.125rem ${palette.grey['500']}`,
    borderRadius: '50%',
    width: '0.5rem',
    height: '0.5rem',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0 0.5rem',
    ':hover': {
      opacity: { xs: '0.2', md: 1 }
    }
  },
  carouselDotSelected: {
    opacity: 1,
    ':hover': {
      opacity: 1
    }
  }
};

export default styles;
