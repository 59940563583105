// lib
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import deepmerge from '@mui/utils/deepmerge';
import Image from 'next/image';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Carousel, { Props as CarouselProps } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// styles-utils
import { lightTheme } from '../../theme/index';
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';
import useClientOnly from '../../utils/customHooks/useClientOnly';

type TestimonialCardProps = {
  image: string;
  altImg: string;
  name: string;
  jobTitle: string;
  nameDesignation: string;
  testimony: string;
  richTextTestimonay: string;
};

export type LibTestimonialCarouselProps = Partial<CarouselProps> & {
  testimonialArray: Array<TestimonialCardProps>;
  headingText?: string;
};

function TypographyName(props: TypographyProps) {
  return <Typography {...props} id={undefined} />;
}

const overrides = {
  ...markdownOverrides,
  h2: {
    component: Typography,
    props: { variant: 'h2', sx: styles.title }
  },
  h3: {
    component: TypographyName,
    props: { variant: 'h3', sx: styles.italicText, className: 'name' }
  },
  h4: {
    component: TypographyName,
    props: { variant: 'h4', sx: styles.italicText, className: 'name' }
  },
  h5: {
    component: Typography,
    props: { variant: 'h5', sx: styles.centerHeading }
  },
  p: {
    component: Typography,
    props: {
      variant: 'body1',
      className: 'job-title'
    }
  },
  italic: {
    component: Typography,
    props: {
      variant: 'body1',
      sx: styles.testimony
    }
  }
};

function TestimonialCard({
  image,
  altImg,
  jobTitle,
  name,
  nameDesignation
}: Partial<TestimonialCardProps>) {
  const { breakpoints } = lightTheme;
  const mobileScreen = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box component="div" sx={styles.slideRoot} key={`${name}_${jobTitle}`}>
      <Box sx={styles.imgNameWrapper}>
        <Image
          src={image}
          alt={name || altImg}
          height={mobileScreen ? 80 : 200}
          width={mobileScreen ? 80 : 200}
          loading="lazy"
          priority={false}
        />
        <Box sx={styles.nameWrapper}>
          {name && (
            <Typography className="name" variant="body1">
              {name}
            </Typography>
          )}
          {jobTitle && (
            <Typography className="job-title" variant="h3">
              {jobTitle}
            </Typography>
          )}
          {nameDesignation && (
            <Markdown options={{ overrides }}>{nameDesignation}</Markdown>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function TestimonialCarousel({
  testimonialArray,
  headingText,
  ...props
}: LibTestimonialCarouselProps) {
  const { hasMounted } = useClientOnly();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const section = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  const containerClassName = hasMounted ? '' : `ssr_markup`;

  const syncState = () => {
    const { current } = section;

    if (current && isXLargeScreen) {
      setPaddingLeft(current.offsetWidth * 0.37);
      setPaddingRight(current.offsetWidth * 0.12);
    } else if (current && isLargeScreen) {
      setPaddingLeft(current.offsetWidth * 0.08);
      setPaddingRight(current.offsetWidth * 0.12);
    } else if (current && isMediumScreen) {
      setPaddingLeft(current.offsetWidth * 0.1);
      setPaddingRight(current.offsetWidth * 0.08);
    } else if (current && isSmallScreen) {
      setPaddingLeft(current.offsetWidth * 0);
      setPaddingRight(current.offsetWidth * 0.2);
    }
  };

  useEffect(() => {
    const requiredBox = document.getElementsByClassName('testimonyBox');

    let maxHeightNumber = 0;

    for (let i = 0; i < requiredBox.length; i += 1) {
      if (requiredBox.item(i).clientHeight > maxHeightNumber) {
        maxHeightNumber = requiredBox.item(i).clientHeight;
      }
    }

    setMaxHeight(maxHeightNumber);
  }, []);

  useEffect(syncState, [section, isSmallScreen, isMediumScreen, isLargeScreen]);

  return (
    <Box sx={styles.mainContainer} component="section">
      <Box sx={styles.root} ref={section} className={containerClassName}>
        {headingText && (
          <Markdown options={{ overrides }}>{headingText}</Markdown>
        )}
        <Carousel
          autoPlay
          infinite
          autoPlayInterval={2000}
          {...props}
          activeIndex={activeSlide}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          onResized={syncState}
          onSlideChanged={(e) => setActiveSlide(e.slide)}
          items={testimonialArray.map((item) => (
            <TestimonialCard {...item} key={`${item.name}_${item.jobTitle}`} />
          ))}
          renderDotsItem={(e) => (
            <Box
              sx={deepmerge(
                styles.carouselDot,
                e.isActive ? { ...styles.carouselDotSelected } : {}
              )}
            />
          )}
          renderNextButton={() => (
            <Box
              sx={!isMediumScreen ? styles.imageOverlay : styles.rightControl}
              id="right-control"
            >
              <ArrowForwardIosRoundedIcon sx={styles.arrowStylesRight} />
            </Box>
          )}
          renderPrevButton={() => (
            <Box
              sx={!isMediumScreen ? styles.imageOverlay : styles.leftControl}
              id="left-control"
            >
              <ArrowBackIosNewRoundedIcon sx={styles.arrowStylesLeft} />
            </Box>
          )}
        />
        <Box sx={styles.divider}>
          <hr className="HRule" />
        </Box>

        {maxHeight ? (
          <Box sx={{ ...styles.testimonyWrapper, height: maxHeight }}>
            <Typography sx={styles.testimony} variant="caption">
              {testimonialArray[activeSlide].testimony &&
                testimonialArray[activeSlide].testimony}
              {testimonialArray[activeSlide].richTextTestimonay && (
                <Markdown options={{ overrides }}>
                  {testimonialArray[activeSlide].richTextTestimonay}
                </Markdown>
              )}
            </Typography>
          </Box>
        ) : (
          testimonialArray.map((item, index) => (
            <Box
              sx={styles.testimonyWrapper}
              style={{ display: activeSlide === index ? 'flex' : 'none' }}
              className="testimonyBox"
              key={item.richTextTestimonay}
            >
              <Typography sx={styles.testimony} variant="caption">
                {item.testimony && item.testimony}
                {item.richTextTestimonay && (
                  <Markdown options={{ overrides }}>
                    {item.richTextTestimonay}
                  </Markdown>
                )}
              </Typography>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}
